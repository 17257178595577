@tailwind base;
@tailwind components;
@tailwind utilities;

/* Heading Color */
.text-heading{
  @apply text-blue-gray-950
}
.text-subheading{
  @apply text-blue-gray-900
}
.text-paragraph{
  @apply text-blue-gray-800
}
.menu-hover {
  @apply hover:text-blue-gray-600 hover:underline
}
/* Navbar */
.product-logo{
  @apply w-12 h-12;
}

.activeState{
  @apply text-blue-500 py-2 px-2 rounded block md:flex
}
.normalState{
  @apply hover:bg-blue-100 py-2 px-2 rounded block md:flex
}
.dropState{
  @apply flex gap-x-0.5 items-center hover:bg-blue-100 py-2 px-2 rounded   
}
.activeDropState{
  @apply hover:bg-blue-100 px-4 md:px-2 py-2 rounded 
}

/* Footer */
.footer {
  @apply w-full mx-auto text-heading px-5 md:px-12 py-5;
}
.footer-logo{
  @apply w-10 h-10 md:w-12 md:h-12;
}
/* 404 Error Page and Success Page */
.error-page{
  @apply grid grid-cols-1 content-center text-center;
}

.success-page{
  @apply text-center w-11/12 mx-auto
}
.error-page__title,
.success-page-title {
  @apply text-base font-medium text-blue-gray-900 ;
}

.error-page__heading {
  @apply text-8xl md:text-9xl font-primary-bold text-blue-gray-900;
}

.error-page__text,
.success-page-text {
  @apply text-blue-gray-900;
}

.error-page__btn {
  @apply w-40 h-12 rounded mx-auto my-5 text-white py-2 px-3 font-primary-medium text-base bg-gray-900 hover:bg-gray-800;
}

.custom-btn {
  @apply w-40 h-10 md:h-12 rounded-lg mx-auto my-5 text-white py-2 px-3 font-primary-bold text-base bg-green-600 hover:bg-green-500;
}

/* Profile Image Icon */
.profile-icon {
  @apply h-12 w-12 object-cover rounded-full cursor-pointer;
}

.profile-list {
  @apply p-2 text-sm font-semibold lg:text-lg cursor-pointer text-blue-gray-900 rounded hover:bg-gray-100;
}

/* Forms */
.form-label {
  @apply block text-base font-primary-medium text-blue-gray-900;
}

.form-input-text {
  @apply my-1 px-2 py-2 outline-none w-full text-sm font-primary-regular border border-primary-gray focus:outline-none focus:border-sky-500 focus:shadow-none  rounded-lg;
}

.form-textarea {
  @apply my-1 px-2 pt-2 outline-none w-full text-sm font-primary-regular resize-none border border-primary-gray rounded;
}

.form-error-msg {
  @apply text-red-500 text-xs mt-1;
}

.form-btn {
  @apply md:w-4/12 p-2 *:lg:w-3/12 mt-3 lg:px-3 text-sm lg:text-base font-primary-bold leading-5 bg-green-500 text-white rounded shadow-sm hover:bg-green-400 text-center;
}

.form-indicator {
  @apply text-red-500;
}

/* Form Data Table (Product Table) */
.table-head {
  @apply font-primary-semibold text-blue-gray-900;
}

.table-data {
  @apply font-primary-regular text-blue-gray-900;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Custom Fonts */
@font-face {
  font-family: "gotham-ultra";
  src: url("./assets/fonts/Gotham-Ultra.otf");
  font-style: normal;
}

@font-face {
  font-family: "gotham-black";
  src: url("./assets/fonts/Gotham-Black.otf");
  font-style: normal;
}

@font-face {
  font-family: "gotham-bold";
  src: url("./assets/fonts/Gotham-Bold.otf");
  font-style: normal;
}

@font-face {
  font-family: "gotham-medium";
  src: url("./assets/fonts/Gotham-Medium.otf");
  font-style: normal;
}

@font-face {
  font-family: "gotham-book";
  src: url("./assets/fonts/Gotham-Book.otf");
  font-style: normal;
}

@font-face {
  font-family: "gotham-light";
  src: url("./assets/fonts/Gotham-Light.otf");
  font-style: normal;
}

@font-face {
  font-family: "gotham-extralight";
  src: url("./assets/fonts/Gotham-XLight.otf");
  font-style: normal;
}

@font-face {
  font-family: "gotham-thin";
  src: url("./assets/fonts/Gotham-Thin.otf");
  font-style: normal;
}

/* Kumbh Fonts */
@font-face {
  font-family: "primary-black";
  src: url("./assets/fonts/KumbhSans-Black.ttf");
  font-style: normal;
}

@font-face {
  font-family: "primary-bold";
  src: url("./assets/fonts/KumbhSans-Bold.ttf");
  font-style: normal;
}

@font-face {
  font-family: "primary-semibold";
  src: url("./assets/fonts/KumbhSans-SemiBold.ttf");
  font-style: normal;
}

@font-face {
  font-family: "primary-extrabold";
  src: url("./assets/fonts/KumbhSans-ExtraBold.ttf");
  font-style: normal;
}

@font-face {
  font-family: "primary-extralight";
  src: url("./assets/fonts/KumbhSans-ExtraLight.ttf");
  font-style: normal;
}

@font-face {
  font-family: "primary-light";
  src: url("./assets/fonts/KumbhSans-Light.ttf");
  font-style: normal;
}

@font-face {
  font-family: "primary-medium";
  src: url("./assets/fonts/KumbhSans-Medium.ttf");
  font-style: normal;
}

@font-face {
  font-family: "primary-regular";
  src: url("./assets/fonts/KumbhSans-Regular.ttf");
  font-style: normal;
}

@font-face {
  font-family: "primary-thin";
  src: url("./assets/fonts/KumbhSans-Thin.ttf");
  font-style: normal;
}

/* Scanner Styles */
.video-scanner {
  width: 340px;
  height: 250px;
  max-width: 1000px;
  max-height: 600px;
  display: block;
  margin: 0 auto;
  /* border:4px solid #124ede;
  border-radius: 25px; */
}